export const downloadFromBlob = (name, data) => {
  const url = window.URL.createObjectURL(data)
  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', name)

  document.body.appendChild(link)
  link.click()
  link.remove()
}

export function downloadFile(response) {
  const contentDisposition = response.headers['content-disposition']
  const blob = new Blob([response.data], { type: response.data.type })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  let filename = 'unknown'
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(contentDisposition)
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, '')
  }
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}
