import mutationTypes from './mutation-types'
import { isEqual } from 'lodash-es'

export default {
  [mutationTypes.SET_EVENT_TYPE](state, type) {
    state.appEventType = type
  },
  [mutationTypes.SET_STATE](state, { inputType, inputQuery, duplicates }) {
    state.inputType = inputType
    state.inputQuery = inputQuery
    state.duplicatesList = duplicates
  },
  [mutationTypes.SET_ACTIVE_VIEW_TYPE](state, type) {
    state.activeViewType = type
  },
  [mutationTypes.OPEN_DIALOG](state) {
    state.isDialogActive = true
  },
  [mutationTypes.CLOSE_DIALOG](state) {
    state.isDialogActive = false
    state.itemDataMatch = false
    state.itemConflictsFound = false
  },
  [mutationTypes.SET_DIALOG_CONTENT](state, data) {
    state.dialogContent = data
  },
  /**
   * This logic should be in the actions
   * Mutations must only set values.
   */
  [mutationTypes.CHECK_FOR_ITEM_CONFLICTS](state, item) {
    const hasConflicts = isEqual(item, state.inputQuery)

    state.appEventType = null

    if (hasConflicts) {
      state.itemConflictsFound = true
    } else {
      state.itemDataMatch = true
    }
  },
  [mutationTypes.SET_DUPLICATES_TO_REVIEW_STATS](state, duplicates) {
    state.chartDuplicatesToReviewOnEntry = duplicates
  },
  [mutationTypes.SET_DUPLICATES_TO_REVIEW](state, duplicates) {
    state.tableDuplicatesToReviewOnEntry = duplicates
  },
  [mutationTypes.SET_DATA_OFFENDERS](state, data) {
    state.dataOffenders = data
  },
}
