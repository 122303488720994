import { entityColors } from '@/api/dupe-sets/dupe-sets'

export default {
  results: (state) => {
    return state.duplicatesList.items.length
  },
  userHasEditPermissions: (state) => {
    return state.duplicatesList.settings.hasEditPermissions
  },
  noResults: (state) => {
    return Object.values(state.inputQuery).some(
      (field) => field === null || field === ''
    )
  },
  noDuplicatesFound: (state) => {
    return !state.duplicatesList.items.length
  },
  duplicatesFound: (state) => {
    return state.duplicatesList.items.length > 0
  },
  isBlockedByAdmin: (state) => {
    return state.duplicatesList.settings.isBlockedByAdmin
  },
  isWarnByAdmin: (state) => {
    return state.duplicatesList.settings.isWarnByAdmin
  },
  dupeSetsToReviewStatsByEntity: ({ chartDuplicatesToReviewOnEntry }) => {
    return aggregateCountByProperty(
      chartDuplicatesToReviewOnEntry,
      'entity_name',
      entityColors
    )
  },
  dupeSetsToReviewCount: ({ chartDuplicatesToReviewOnEntry }) => {
    return countStats(chartDuplicatesToReviewOnEntry)
  },
}

// These 2 functions were copied from the dashboard store.
// If the API return the same structure as it is mocked
// find a way to reuse the functions
const countStats = (stats) => {
  if (!stats) return 0

  const countIndex = stats.attributes.findIndex((el) => el === 'count')

  return stats.values.reduce((acc, stat) => acc + stat[countIndex], 0)
}

const aggregateCountByProperty = (stats, property, colors) => {
  if (!stats) return []

  const propertyIndex = stats.attributes.findIndex((el) => el === property)
  const countIndex = stats.attributes.findIndex((el) => el === 'count')

  const countByProperty = stats.values.reduce((acc, currentStat) => {
    return {
      ...acc,
      [currentStat[propertyIndex]]:
        currentStat[countIndex] + (acc[currentStat[propertyIndex]] || 0),
    }
  }, {})

  return Object.keys(countByProperty).map((key) => {
    return {
      label: key,
      count: countByProperty[key],
      color: colors[key],
    }
  })
}
