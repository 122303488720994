import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  activeViewType: '',
  appEventType: null,
  inputType: 'contact',
  inputQuery: {
    id: 1234566,
    firstName: 'Josip',
    lastName: 'Kristo',
    jobTitle: 'Manager',
    accountName: 'Wells Fargo Newark',
    email: 'john.smith@wf-newark.com',
    bussinessPhone: '217',
    mobilePhone: '217-920-4582',
  },
  duplicatesList: {
    items: [],
    pagination: {},
    settings: {
      isBlockedByAdmin: false,
      isWarnByAdmin: false,
      hasEditPermissions: true,
    },
  },
  chartDuplicatesToReviewOnEntry: null,
  tableDuplicatesToReviewOnEntry: [],
  dataOffenders: [],
  itemConflictsFound: false,
  itemDataMatch: false,
  dialogContent: {},
  isDialogActive: false,
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
