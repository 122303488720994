export default {
  SET_EVENT_TYPE: 'SET_EVENT_TYPE',
  SET_STATE: 'SET_STATE',
  SET_ACTIVE_VIEW_TYPE: 'SET_ACTIVE_VIEW_TYPE',
  OPEN_DIALOG: 'OPEN_DIALOG',
  CLOSE_DIALOG: 'CLOSE_DIALOG',
  SET_DIALOG_CONTENT: 'SET_DIALOG_CONTENT',
  CHECK_FOR_ITEM_CONFLICTS: 'CHECK_FOR_ITEM_CONFLICTS',
  SET_DUPLICATES_TO_REVIEW_STATS: 'SET_DUPLICATES_TO_REVIEW_STATS',
  SET_DUPLICATES_TO_REVIEW: 'SET_DUPLICATES_TO_REVIEW',
  SET_DATA_OFFENDERS: 'SET_DATA_OFFENDERS',
}
