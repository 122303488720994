import { formatDistanceToNow, format, parseISO, formatDistance } from 'date-fns'

export function formatDate(value, filterFormat = 'MMM dd, yyyy') {
  if (value) {
    if (value instanceof Date) {
      return format(value, filterFormat)
    } else {
      return format(parseISO(value), filterFormat)
    }
  }

  return ''
}

export function timeAgo(value) {
  if (!value) return ''

  const currDate = new Date()
  const valDate = new Date(value)

  return formatDistance(currDate, valDate)
}

export function msToTime(ms) {
  // let seconds = Math.floor((ms / 1000) % 60)
  let minutes = Math.floor((ms / (1000 * 60)) % 60)
  let hours = Math.floor((ms / (1000 * 60 * 60)) % 24)

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  // seconds = (seconds < 10) ? '0' + seconds : seconds

  return hours + 'h ' + minutes + "''"
}

export const formatUtcDateFromNow = (value) => {
  return formatDistanceToNow(parseISO(value))
}
